import {
  ADD_MULTIPLE_CONFIGURATION,
  ADD_MULTIPLE_CONFIGURATION_CR,
  GET_SALES_BASIS_SUCCESS,
  ACCESS_DATA_SUCCESS,
  SECOND_API_CONFIG_SUCCESS,
  HISTORY_SUCCESS,
  ORGANIZATION_ACCESS_SUCCESS,
  USER_ROLE_ACCESS_SUCCESS,
  DEFAULT_VALUES_CALC_SIM,
  DEFAULT_VALUES_TARG_DAT,
  DEFAULT_VALUES_CONT_CHAN,
  DEFAULT_VALUES_FINAN_SUM,
  DEFAULT_VALUES_FINANCIAL,
  APPROVAL_GROUP_SUCCESS,
  FAQ_SUCCESS,
  COLLABORATION_PORTAL_SUCCESS,
  FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS,
  GET_FINANCIAL_APPROVAL_SUCCESS,
  MCF_INTEGRATION_SUCCESS,
  GET_CUSTOMER_PAYMENT_SUCCESS,
  GET_INTEGRATION_RECONCILATION_SUCCESS,
  FETCH_START,
  FETCH_END,
  FETCH_START_ORG,
  FETCH_END_ORG,
  FORM_FIELDS_ALL_DATA,
  FORM_FIELDS_ALL_DATA_ER,
  TARGET_SOURCE_ATTRIBUTE_ALL_DATA,
  TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA,
  FORM_FIELDS_ALL_DATA_NEW,
  ADD_MULTIPLE_CONFIGURATION_KF,
  ADD_MULTIPLE_CONFIGURATION_AV,
  LOAD_FIELDVALUES_ON_APP_TYPE,
  USER_ROLE_SINGLE,
  ATTRIBUTE_NAME,
  GET_USER_EMAIL_LINK,
  WORKFLOW_REQUEST_ACCRUAL,
  WORKFLOW_REQUEST_PAYMENT,
  ACCESS_DATA_NEW_SUCCESS,
  KEY_FIGURE_NAME,
  CONTRACT_TRENDS,
  CONTRACT_TRENDS_MULTIPLE,
  CALCULATION_SIMULATION_NUMBERS,
  QUOTA_LEVEL,
  ORG_DEFAULTS,
  WORKFLOW_REQUEST_PROMOTION,
  CLAIM_ERROR_COUNT,
  APP_RIGHTS,
  GET_CALCULATION_SUMMARY_SUCCESS,
  DELETE_CALCULATION_SIMULATION_DATA_SUCCESS,
  ORG_APP_TITLE_SUCCESS,
  UPLOAD_MASS_FILE,
  TIER_EVALUATION_LEVEL,
  TABLES_NAMES_SUCCESS,
  DEAFULT_VALUES_LBL_DISP,
  DELETE_MASTER_DATA_SUCCESS,
  DELETE_MASTER_DATA_FIELD_SUCCESS,
  CONTRACT_TRENDS_V2,
  CONTRACT_DISPLAY,
  COLLABORATED_DATA,
  GET_ACC_VS_PAY_SUCCESS,
  GET_EXTERNAL_ACCURAL_DATA_SUCCESS,
  GET_EXTERNAL_PAYMENT_DATA_SUCCESS,
  GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS,
  CONFIGURATION_DESIGNER_SUCCESS,
  REPORT_TYPE_FILTER_SUCCESS,
  REPORT_TYPE_DATA_ALL,
  REPORT_TYPE_DATA_SINGLE,
  REPORT_ON_ROLL_ALL,
  GET_CONTRACT_ATTRIBUTES_SUCCESS,
  CONTRACT_ALL_ATTRIBUTES,
  GET_DAILY_SIMULATIONS_REPORT_SUCCESS,
  POSTINGS_DATA,
  WORKFLOW_MAINTENANCE_DATA,
  MANDATORY_ELIGIBILITY_RULES_SUCCESS,
  GET_STANDARD_COMPLIANCE_SUCCESS,
  MESSAGE_MAINTENANCE_ALL_DATA,
  MESSAGE_MAINTENANCE_DATA_SINGLE,
  MESSAGE_MAINTENANCE_ID_DATA,
} from './ActionTypes';
import { toast } from 'react-toastify';
import axios from '../../../../axios.conf';
import { history } from '../../../../components/Helpers';
import { ServerError } from '../../../../library/constants';
import secureLocalStorage from 'react-secure-storage';

export const addMultipleConfigurationSuccess = (data) => {
  return {
    type: ADD_MULTIPLE_CONFIGURATION,
    data,
  };
};
export const addMultipleConfigurationSuccessKeyFigure = (data) => {
  return {
    type: ADD_MULTIPLE_CONFIGURATION_KF,
    data,
  };
};

export const addMultipleConfiguration = (data) => {
  return (dispatch) => {
    dispatch(addMultipleConfigurationSuccess(data));
    addMultipleConfigurationApi(data)
      .then((response) => {
        toast.success('Successfully Added');
        dispatch(getDefaultValues());
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const addMultipleConfigurationApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('form_fields', data, { headers: headers });
  return result;
};

// get sales Basis
export const getSalesBasisData = () => {
  return (dispatch) => {
    getSalesBasisApi()
      .then((response) => {
        dispatch(getSalesBasisSuccess(response.data.response[0]));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};

export const getSalesBasisSuccess = (data) => {
  return {
    type: GET_SALES_BASIS_SUCCESS,
    data,
  };
};

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const fetchStartOrganization = () => {
  return {
    type: FETCH_START_ORG,
  };
};

export const fetchEndOrganization = (data) => {
  return {
    type: FETCH_END_ORG,
    data,
  };
};

const getSalesBasisApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('config/sales-basis', { headers: headers });
  return result;
};

// post sales Basis
export const postSalesBasisData = (data) => {
  return (dispatch) => {
    postSalesBasisApi(data).then((response) => {
      toast.success('Successfully added.');
    });
  };
};

const postSalesBasisApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('config/sales-basis', data, { headers: headers });
  return result;
};

// update sales Basis
export const updateSalesBasisData = (data, id) => {
  return (dispatch) => {
    updateSalesBasisApi(data, id)
      .then((response) => {
        toast.success('Successfully updated.');
      })
      .catch((error) => { });
  };
};

const updateSalesBasisApi = (data, id) => {
  var formData = data;
  for (var propName in formData) {
    if (
      formData[propName] === '' ||
      //formData[propName] === [] ||
      formData[propName].length === 0
    ) {
      delete formData[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('config/sales-basis/' + id, formData, {
    headers: headers,
  });
  return result;
};

//get default values
export const getDefaultValues = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
  page,
) => {
  return (dispatch) => {
    getDefaultValuesApi(applicationType, sourceDataType, on, fieldId, page)
      .then((response) => {
        if (on != 'on') {
          dispatch(addMultipleConfigurationSuccess(response.data.response));
          sessionStorage.setItem(
            'currencyDropdown',
            JSON.stringify(response.data.response),
          );
        }
        if (fieldId === 'attribute_name') {
          dispatch(setAttributeNameSuccess(response.data.response));
        }
        if (fieldId === 'key_figure_name') {
          dispatch(setKeyFigureNameSuccess(response.data.response));
        }
        if (fieldId === 'quota_level') {
          dispatch(setQuotaLevelSuccess(response.data.response));
        }
        if (fieldId === 'tier_evaluation_level') {
          dispatch(setTierEvaluationLevelSuccess(response.data.response));
        }
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};

const getDefaultValuesApi = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
  page,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (applicationType === 'noKeyWords') {
    var result = axios.get('form_fields?form_id=IMA_CONFIG_CONTRACT&limit=0', {
      headers: headers,
    });
  } else if (page === 'Display Designer') {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_CALSIM_FINPOSTING&app_source_type=' +
      applicationType +
      '&data_source_type=' +
      sourceDataType +
      '&limit=0',
      { headers: headers },
    );
  } else if (page === 'Contract Designer') {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_CONTRACT&app_source_type=' +
      applicationType +
      '&limit=0',
      { headers: headers },
    );
  } else if (page === 'Financial Summary') {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_FINANCIAL_POSTING&app_source_type=' +
      applicationType +
      '&limit=0',
      { headers: headers },
    );
  } else if (fieldId === 'quota_level') {
    var result = axios.get(
      'form_fields?app_source_type=' +
      applicationType +
      '&form_id=IMA_CONFIG_CONTRACT&field_id=' +
      fieldId +
      '&limit=0',
      { headers: headers },
    );
  } else if (page === 'filter-fields') {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_CONTRACT&app_source_type=' +
      applicationType +
      '&field_id.in=' +
      fieldId,
      { headers: headers },
    );
  } else {
    if (on == 'on') {
      var result = axios.get(
        'form_fields?form_id=IMA_CONFIG_CONTRACT&&data_source_type=' +
        sourceDataType +
        '&app_source_type=' +
        applicationType +
        '&field_id=' +
        fieldId,
        { headers: headers },
      );
    } else if (on == 'Profit Optimization') {
      var result = axios.get(
        'form_fields?form_id=IMA_CONFIG_CONTRACT&limit=0&app_source_type=Customer Rebate',
        { headers: headers },
      );
    } else if (applicationType) {
      var result = axios.get(
        'form_fields?form_id=IMA_CONFIG_CONTRACT&limit=0&app_source_type=' +
        applicationType,
        { headers: headers },
      );
    } else {
      var result = axios.get(
        'form_fields?form_id=IMA_CONFIG_CONTRACT&limit=0&app_source_type=' +
        sessionStorage.getItem('application'),
        { headers: headers },
      );
    }
  }
  return result;
};

//get default values key figure
export const getDefaultValuesKeyFigure = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
  page,
) => {
  return (dispatch) => {
    getDefaultValuesKeyFigureApi(
      applicationType,
      sourceDataType,
      on,
      fieldId,
      page,
    )
      .then((response) => {
        dispatch(
          addMultipleConfigurationSuccessKeyFigure(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getDefaultValuesKeyFigureApi = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
  page,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (page === 'FormulaLab') {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_CONTRACT&app_source_type=' +
      applicationType +
      '&field_id=' +
      fieldId +
      '&data_source_type=' +
      sourceDataType,
      { headers: headers },
    );
  } else {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_CONTRACT&&data_source_type=' +
      sourceDataType +
      '&app_source_type=' +
      applicationType +
      '&field_id=' +
      fieldId,
      { headers: headers },
    );
  }
  return result;
};

//get default values attribute name
export const getDefaultValuesAttributeValue = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
  page,
) => {
  return (dispatch) => {
    getDefaultValuesAttributeValueApi(
      applicationType,
      sourceDataType,
      on,
      fieldId,
    )
      .then((response) => {
        dispatch(
          addMultipleConfigurationSuccessAttributeValue(response.data.response),
        );
      })
      .catch((error) => { });
  };
};
export const addMultipleConfigurationSuccessAttributeValue = (data) => {
  return {
    type: ADD_MULTIPLE_CONFIGURATION_AV,
    data,
  };
};

export const setAttributeNameSuccess = (data) => {
  return {
    type: ATTRIBUTE_NAME,
    data,
  };
};
export const setQuotaLevelSuccess = (data) => {
  return {
    type: QUOTA_LEVEL,
    data,
  };
};
export const setTierEvaluationLevelSuccess = (data) => {
  return {
    type: TIER_EVALUATION_LEVEL,
    data,
  };
};

export const setKeyFigureNameSuccess = (data) => {
  return {
    type: KEY_FIGURE_NAME,
    data,
  };
};

const getDefaultValuesAttributeValueApi = (
  applicationType,
  sourceDataType,
  on,
  fieldId,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_CONTRACT&&data_source_type=' +
    sourceDataType +
    '&app_source_type=' +
    applicationType +
    '&field_id=' +
    fieldId,
    { headers: headers },
  );
  return result;
};

//get history--not used
export const getHistory = () => {
  return (dispatch) => {
    getHistoryApi()
      .then((response) => {
        dispatch(getHistorySuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getHistoryApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'contracts/' + localStorage.getItem('currentAddContractID') + '/history',
    { headers: headers },
  );
  return result;
};

export const getHistorySuccess = (data) => {
  return {
    type: HISTORY_SUCCESS,
    data,
  };
};

//second get funtion
export const secondGetConfiguration = (key, source, app) => {
  return (dispatch) => {
    secondGetConfigurationApi(key, source, app)
      .then((response) => {
        dispatch(secondGetConfigurationSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

export const secondGetConfigurationSuccess = (data) => {
  return {
    type: SECOND_API_CONFIG_SUCCESS,
    data,
  };
};

const secondGetConfigurationApi = (key, source, app) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (source && app) {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_MULTIPLE&field_id=' +
      key +
      '&data_source_type=' +
      source +
      '&app_source_type=' +
      app,
      { headers: headers },
    );
  } else {
    var result = axios.get(
      'form_fields?form_id=IMA_CONFIG_MULTIPLE&field_id=' + key,
      { headers: headers },
    );
  }
  return result;
};

//put multiple configurations into
export const putMultipleConfiguration = (
  data,
  id,
  applicationType,
  type,
  sourceType,
) => {
  return (dispatch) => {
    putMultipleConfigurationApi(data, id)
      .then((response) => {
        if (type === 'Eligibility Designer') {
          dispatch(
            getDefaultValues(
              applicationType,
              sourceType,
              'on',
              'attribute_name',
            ),
          );
        } else if (type === 'Calculation Designer') {
          dispatch(
            getDefaultValues(
              applicationType,
              sourceType,
              'on',
              'key_figure_name',
            ),
          );
        } else {
          dispatch(
            getDefaultValues(
              applicationType,
              null,
              null,
              null,
              'Contract Designer',
            ),
          );
        }
        toast.success('Successfully Updated');
        // window.location.reload();
      })
      .catch((error) => { });
  };
};

const putMultipleConfigurationApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('form_fields/' + id, data, { headers: headers });
  return result;
};

//put multiple configurations into kumar api new
export const putMultipleConfigurationNew = (
  data,
  id,
  applicationType,
  sourceDataType,
  command,
) => {
  return (dispatch) => {
    putMultipleConfigurationNewApi(
      data,
      id,
      applicationType,
      sourceDataType,
      command,
    )
      .then((response) => {
        toast.success('Successfully Updated');
        if (command === 'field-label-designer') {
          dispatch(
            getDefaultValuesAllDataNew(
              command,
              applicationType,
              sourceDataType,
            ),
          );
        } else {
          dispatch(getDefaultValuesAllDataNew());
        }
      })
      .catch((error) => {
        ////toast.error(error.response.data.error.message);
      });
  };
};

const putMultipleConfigurationNewApi = (
  data,
  id,
  applicationType,
  sourceDataType,
  command,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'field-label-designer?app_type=' +
    applicationType +
    '&data_source_type=' +
    sourceDataType,
    data,
    { headers: headers },
  );
  return result;
};

//get access data old api
export const getAccessData = (userRole) => {
  return (dispatch) => {
    getAccessDataApi(userRole)
      .then((response) => {
        dispatch(getAccessDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAccessDataApi = (userRole) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (userRole) {
    var result = axios.get('config/access?name=' + userRole, {
      headers: headers,
    });
  } else {
    var result = axios.get('config/access?limit=100', { headers: headers });
  }
  return result;
};

export const getAccessDataSuccess = (data) => {
  return {
    type: ACCESS_DATA_SUCCESS,
    data,
  };
};

//get access data old api
export const getAccessDataNew = () => {
  return (dispatch) => {
    getAccessDataNewApi().then((response) => {
      dispatch(getAccessDataNewSuccess(response.data.response));
    });
  };
};

const getAccessDataNewApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('user-role', { headers: headers });
  return result;
};

export const getAccessDataNewSuccess = (data) => {
  return {
    type: ACCESS_DATA_NEW_SUCCESS,
    data,
  };
};
export const getOrganizationAppTitle = () => {
  return (dispatch) => {
    getOrganizationAppTitleApi().then((response) => {
      dispatch(getOrganizationAppTitleSuccess(response.data.response));
    });
  };
};
const getOrganizationAppTitleApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('organizations?app_title=yes', { headers: headers });
  return result;
};
export const getOrganizationAppTitleSuccess = (data) => {
  return {
    type: ORG_APP_TITLE_SUCCESS,
    data,
  };
};
//post access data
export const postAccessData = (data, id) => {
  return (dispatch) => {
    putAccessDataApi(data, id)
      .then((response) => {
        dispatch(getAccessDataNew());
      })
      .catch((error) => { });
  };
};

const putAccessDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.put('user-role/' + id, data, { headers: headers });
  return result;
};

//change enabled
export const putChangeEnabled = (id, enabled) => {
  return (dispatch) => {
    putChangeEnabledApi(id, enabled)
      .then((response) => {
        toast.success('Successfully Updated.');
      })
      .catch((error) => { });
  };
};

const putChangeEnabledApi = (id, enabled) => {
  var formData = {
    enabled: enabled,
  };
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post('form_fields/' + id + '/access', formData, {
    headers: headers,
  });
  return result;
};

//change enabled  new
export const putChangeEnabledNew = (data, application, source, page) => {
  return (dispatch) => {
    putChangeEnabledNewAPI(data, application, source, page)
      .then((response) => {
        if (page === 'Contract Display Designer')
          dispatch(
            getDefaultValues(
              application,
              source,
              null,
              null,
              'Contract Designer',
            ),
          );
        else
          dispatch(
            getDefaultValues(
              application,
              source,
              null,
              null,
              'Display Designer',
            ),
          );
        toast.success('Successfully Updated.');
      })
      .catch((error) => { });
  };
};

const putChangeEnabledNewAPI = (data, application, source, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (page === 'Common Display Designer') {
    var result = axios.put('cal-fin-display-by-id', data, { headers: headers });
  } else {
    var result = axios.put('contract-role-by-id', data, { headers: headers });
  }
  return result;
};

//orgnanization access
export const postOrganizationAccess = (formData, id, type) => {
  return (dispatch) => {
    dispatch(fetchStartOrganization());
    postOrganizationAccessApi(formData, id, type)
      .then((response) => {
        if (type == 'edit') {
          dispatch(fetchEnd());
          toast.success('Successfully Updated.');
          dispatch(onLoadingOrganizationAccessList());
        } else {
          dispatch(fetchEnd());
          toast.success('Successfully Added.');
          if (
            response.data &&
            response.data.response &&
            response.data.response.admin_password_reset_url
          )
            dispatch(
              fetchEndOrganization(
                response.data.response.admin_password_reset_url,
              ),
            );
          dispatch(onLoadingOrganizationAccessList());
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const postOrganizationAccessApi = (formData, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit')
    result = axios.put('organizations/' + id, formData, { headers: headers });
  else result = axios.post('organizations', formData, { headers: headers });
  return result;
};

//loading list organization
export const onLoadingOrganizationAccessList = () => {
  return (dispatch) => {
    onLoadingOrganizationAccessListApi()
      .then((response) => {
        dispatch(organizationAccessListApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const onLoadingOrganizationAccessListApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('organizations', { headers: headers });
  return result;
};

export const organizationAccessListApiSuccess = (data) => {
  return {
    type: ORGANIZATION_ACCESS_SUCCESS,
    data,
  };
};

//user role access
export const postUserRoleAccess = (formData, mode, id) => {
  return (dispatch) => {
    postUserRoleAccessApi(formData, mode, id)
      .then((response) => {
        toast.success('Successfully Added.');
        dispatch(onLoadingUserRoleAccessList());
        if (
          response.data &&
          response.data.response &&
          response.data.response.user_password_reset_url
        )
          dispatch(
            getUserRoleEmailL(response.data.response.user_password_reset_url),
          );
      })
      .catch((error) => { });
  };
};

const postUserRoleAccessApi = (formData, mode, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (mode === 'edit') {
    var result = axios.put('users/' + id, formData, { headers: headers });
    return result;
  } else {
    var result = axios.post('users', formData, { headers: headers });
    return result;
  }
};

//loading list user role
export const onLoadingUserRoleAccessList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    onLoadingUserRoleAccessListApi()
      .then((response) => {
        dispatch(userRoleAccessListApiSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => { });
  };
};

const onLoadingUserRoleAccessListApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('users', { headers: headers });
  return result;
};

export const userRoleAccessListApiSuccess = (data) => {
  return {
    type: USER_ROLE_ACCESS_SUCCESS,
    data,
  };
};

//add user
export const addUser = (name, fn, id) => {
  return (dispatch) => {
    addUserApi(name, fn, id)
      .then((response) => {
        if (fn === 'Edit') {
          toast.success('Role Updated Successfully');
        } else if (fn === 'delete') {
          toast.success(response.data.response);
        } else {
          toast.success('Successfully Added.');
        }
        dispatch(getAccessDataNew());
      })
      .catch((error) => { });
  };
};

const addUserApi = (name, fn, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var formData = {
    name: name,
  };
  if (fn === 'delete') {
    var result = axios.delete('user-role/' + name, { headers: headers });
  } else if (fn === 'Edit') {
    var result = axios.put('config/access/' + id, formData, {
      headers: headers,
    });
  } else {
    var result = axios.post('config/access', formData, { headers: headers });
  }
  return result;
};

//get default values calculation simulation
export const getDefaultValuesCalculationSimulation = () => {
  return (dispatch) => {
    getDefaultValuesCalculationSimulationApi()
      .then((response) => {
        dispatch(
          defaultValuesCalculationSimulationSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getDefaultValuesCalculationSimulationApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_CALCULATION_SIMULATION&limit=0',
    { headers: headers },
  );
  return result;
};

export const defaultValuesCalculationSimulationSuccess = (data) => {
  return {
    type: DEFAULT_VALUES_CALC_SIM,
    data,
  };
};
// get dafault values target data
export const getDefaultValuesTargetData = () => {
  return (dispatch) => {
    getDefaultValuesTargetDataApi().then((response) => {
      dispatch(defaultValuesTargetDataSuccess(response.data.response));
    });
  };
};

const getDefaultValuesTargetDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_TARGET-DATA-REPORT&limit=0',
    { headers: headers },
  );
  return result;
};

export const defaultValuesTargetDataSuccess = (data) => {
  return {
    type: DEFAULT_VALUES_TARG_DAT,
    data,
  };
};

// get default values for contract change reports
export const getDefaultValuesContractChange = () => {
  return (dispatch) => {
    getDefaultValuesContractChangeApi().then((response) => {
      dispatch(defaultValuesContractChangeSuccess(response.data.response));
    });
  };
};

const getDefaultValuesContractChangeApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_TARGET-DATA-REPORT&limit=0',
    { headers: headers },
  );
  return result;
};

export const defaultValuesContractChangeSuccess = (data) => {
  return {
    type: DEFAULT_VALUES_CONT_CHAN,
    data,
  };
};

//get default values for financial summary reports
export const getDefaultValuesFinancialSummaryReports = () => {
  return (dispatch) => {
    getDefaultValuesFinancialSummaryReportsApi()
      .then((response) => {
        dispatch(
          defaultValuesFinancialSummaryReportsDataSuccess(
            response.data.response,
          ),
        );
      })
      .catch((error) => { });
  };
};

const getDefaultValuesFinancialSummaryReportsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_FINANCIAL_POSTING&limit=0',
    { headers: headers },
  );
  return result;
};

export const defaultValuesFinancialSummaryReportsDataSuccess = (data) => {
  return {
    type: DEFAULT_VALUES_FINAN_SUM,
    data,
  };
};

//get default values financial simulation
export const getDefaultValuesFinancialSimulation = () => {
  return (dispatch) => {
    getDefaultValuesFinancialSimulationApi()
      .then((response) => {
        dispatch(
          defaultValuesFinancialSimulationSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getDefaultValuesFinancialSimulationApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_FINANCIAL_POSTING&limit=0',
    { headers: headers },
  );
  return result;
};

export const defaultValuesFinancialSimulationSuccess = (data) => {
  return {
    type: DEFAULT_VALUES_FINANCIAL,
    data,
  };
};

//approval group
export const getApprovalGroupData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getApprovalGroupDataApi()
      .then((response) => {
        dispatch(getApprovalGroupDataSuccess(response.data.response));
        sessionStorage.setItem(
          'approvalGroupDropdonw',
          JSON.stringify(response.data.response),
        );
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getApprovalGroupDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('approval-group', { headers: headers });
  return result;
};

export const getApprovalGroupDataSuccess = (data) => {
  return {
    type: APPROVAL_GROUP_SUCCESS,
    data,
  };
};

export const postApprovalGroup = (formData) => {
  return (dispatch) => {
    postApprovalGroupApi(formData)
      .then((response) => {
        toast.success('Successfully Added.');
        dispatch(getApprovalGroupData());
      })
      .catch((error) => { });
  };
};

const postApprovalGroupApi = (formData) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post('approval-group', formData, { headers: headers });
  return result;
};

export const putApprovalGroup = (formData, id, type) => {
  return (dispatch) => {
    putApprovalGroupApi(formData, id, type)
      .then((response) => {
        if (type === 'delete') {
          toast.success('Successfully deleted.');
        } else {
          toast.success('Successfully updated.');
        }

        dispatch(getApprovalGroupData());
      })
      .catch((error) => { });
  };
};

const putApprovalGroupApi = (formData, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type === 'delete') {
    var result = axios.delete('approval-group/' + id, { headers: headers });
  } else {
    var result = axios.post('approval-group/' + id, formData, {
      headers: headers,
    });
  }

  return result;
};

//FAQ
export const getFAQData = (isFaq) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFAQDataApi(isFaq)
      .then((response) => {
        if (response.data.response && response.data.response.length > 0) {
          dispatch(getFAQDataSuccess(response.data.response));
          dispatch(fetchEnd());
        } else {
          dispatch(getFAQDataSuccess([]));
          dispatch(fetchEnd());
        }
        // console.log(response.data);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getFAQDataApi = (isFaq) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };

  let result;
  if (isFaq) {
    result = axios.get('faqs?is_faq=true', { headers: headers });
  } else {
    result = axios.get('faqs?is_faq=false', { headers: headers });
  }
  return result;
};

export const getFAQDataSuccess = (data) => {
  return {
    type: FAQ_SUCCESS,
    data,
  };
};

export const postFAQ = (formData, isFaq) => {
  return (dispatch) => {
    postFAQApi(formData)
      .then((response) => {
        toast.success('Successfully Added.');
        dispatch(getFAQData(isFaq));
      })
      .catch((error) => { });
  };
};

const postFAQApi = (formData) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post('faqs', formData, { headers: headers });
  return result;
};

export const putFAQ = (formData, id, type, isFaq) => {
  return (dispatch) => {
    putFAQApi(formData, id, type, isFaq)
      .then((response) => {
        if (type === 'delete') {
          toast.success('Successfully deleted.');
        } else {
          toast.success('Successfully updated.');
        }

        dispatch(getFAQData(isFaq));
      })
      .catch((error) => { });
  };
};

const putFAQApi = (formData, id, type, isFaq) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type === 'delete') {
    var result = axios.delete('faqs/' + id, { headers: headers });
  } else {
    var result = axios.put('faqs/' + id, formData, {
      headers: headers,
    });
  }

  return result;
};

//collaboration portal starts here
export const getCollaborationPortal = (pagination, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCollaborationPortalAPI(pagination, limit)
      .then((response) => {
        dispatch(getCollaborationPortalDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        //toast.error('Something went wrong')
        dispatch(fetchEnd());
      });
  };
};

const getCollaborationPortalAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'collaborations?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getCollaborationPortalDataSuccess = (data) => {
  return {
    type: COLLABORATION_PORTAL_SUCCESS,
    data,
  };
};

export const postCollaborationPortal = (data, editId, type) => {
  return (dispatch) => {
    postCollaborationPortalAPI(data, editId, type)
      .then((response) => {
        if (type == 'delete') {
          toast.success('Successfully deleted.');
          dispatch(getCollaborationPortal(1, 0));
        } else if (type == 'edit') {
          toast.success('Successfully updated.');
          dispatch(getCollaborationPortal(1, 0));
        } else if (type === 'get') {
          dispatch(getCollaborationPortalDataSuccess(response.data.response));
        } else {
          toast.success('Successfully added.');
          dispatch(getCollaborationPortal(1, 0));
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message
        )
          toast.error(error.response.data.error.message);
      });
  };
};

const postCollaborationPortalAPI = (data, editId, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'get') {
    result = axios.get('collaborations/' + editId, { headers: headers });
  } else if (editId) {
    if (type == 'delete') {
      result = axios.delete('collaborations/' + editId, { headers: headers });
    } else
      result = axios.put('collaborations/' + editId, data, {
        headers: headers,
      });
  } else {
    result = axios.post('collaborations', data, { headers: headers });
  }
  return result;
};

// Calculation Summary Report
export const getCalculationSummaryData = (pagination, limit, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCalculationSummaryDataApi(pagination, limit, data).then((response) => {
      dispatch(fetchEnd());
      if (response.data.response.total_record > 0) {
        dispatch(getCalculationSummaryDataSuccess(response.data.response));
        history.push('/calculation-summary-reports/results');
      } else {
        toast.error('No records found');
      }
    });
  };
};
const getCalculationSummaryDataApi = (pagination, limit, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post(
    'calculation_simulations/summary?page=' + pagination + '&limit=' + limit,
    data,
    { headers: headers },
  );
  return result;
};

export const getCalculationSummaryDataSuccess = (data) => {
  return {
    type: GET_CALCULATION_SUMMARY_SUCCESS,
    data,
  };
};
//delete calculation simulation data
export const getDeleteCalculationSimulationData = (data) => {
  return (dispatch) => {
    getDeleteCalculationSimulationDataAPI(data).then((response) => {
      toast.success(response.data.response);
    });
  };
};
const getDeleteCalculationSimulationDataAPI = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ',' ||
      data[propName] === 'Invalid date'
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.delete(
    '/calculation_simulation?',
    { params: data },
    { headers: headers },
  );
  return result;
};
export const getDeleteCalculationSimulationDataSuccess = (data) => {
  return {
    type: DELETE_CALCULATION_SIMULATION_DATA_SUCCESS,
    data,
  };
};
//collaboration porta ends here
//Accrual vs Payment Report
export const getAccrualVsPaymentData = (data, UUID) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAccrualVsPaymentDataApi(data, UUID)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(getAccrualVsPaymentDataSuccess(response.data.response));
          history.push('/accrual-vs-Payment-reports-result');
        } else toast.error('No records found');
      })
      .catch((error) => {
        toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};
const getAccrualVsPaymentDataApi = (data, UUID) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  let result;
  if (UUID) {
    result = axios.post('financial_postings/accrual-vs-payment?request_id=' + UUID, data, {
      headers: headers,
    });
  }
  else {
    result = axios.post('financial_postings/accrual-vs-payment', data, {
      headers: headers,
    });
  }
  return result;
};

export const getAccrualVsPaymentDataSuccess = (data) => {
  return {
    type: GET_ACC_VS_PAY_SUCCESS,
    data,
  };
};

//External Accural Posting

export const getExternalAccuralPostingData = (data, id, pageShow) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getExternalAccuralPostingDataApi(data, id, pageShow)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(
            getExternalAccuralPostingDataSuccess(response.data.response),
          );
          if (pageShow !== 'noResultPage') {
            if (id) {
              history.push({
                pathname: '/external-accural-postings-result',
                state: 'OutboundPostingIntegration',
              });
            } else {
              history.push({
                pathname: '/external-accural-postings-result',
                state: 'None',
              });
            }
          }
        } else toast.error('No records found');
      })
      .catch((error) => {
        // toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};
const getExternalAccuralPostingDataApi = (data, id, pageShow) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (pageShow === 'noResultPage') {
    result = axios.post(
      'accrual-posting-api?callerContext=' + 'approvalScreen',
      data,
      { headers: headers },
    );
  } else {
    if (id) {
      result = axios.post('accrual-posting-api?id=' + id, data, {
        headers: headers,
      });
    } else {
      result = axios.post('accrual-posting-api', data, { headers: headers });
    }
  }
  return result;
};

export const getExternalAccuralPostingDataSuccess = (data) => {
  return {
    type: GET_EXTERNAL_ACCURAL_DATA_SUCCESS,
    data,
  };
};

//External Payment Posting

export const getExternalPaymentPostingData = (data, id, pageShow) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getExternalPaymentPostingDataApi(data, id, pageShow)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(
            getExternalPaymentPostingDataSuccess(response.data.response),
          );
          if (pageShow !== 'noResultPage') {
            if (id) {
              history.push({
                pathname: '/external-payment-postings-result',
                state: 'OutboundPostingIntegration',
              });
            } else {
              history.push({
                pathname: '/external-payment-postings-result',
                state: 'None',
              });
            }
          }
          // history.push("/external-payment-postings-result");
        } else toast.error('No records found');
      })
      .catch((error) => {
        // toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};
const getExternalPaymentPostingDataApi = (data, id, pageShow) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (pageShow === 'noResultPage') {
    result = axios.post(
      'Payment-external-posting?callerContext=' + 'paymentScreen',
      data,
      { headers: headers },
    );
  } else {
    if (id) {
      result = axios.post('Payment-external-posting?id=' + id, data, {
        headers: headers,
      });
    } else {
      result = axios.post('Payment-external-posting', data, {
        headers: headers,
      });
    }
  }
  // var result = axios.post(
  //   "Payment-external-posting",
  //   data,
  //   { headers: headers }
  // );
  return result;
};

export const getExternalPaymentPostingDataSuccess = (data) => {
  return {
    type: GET_EXTERNAL_PAYMENT_DATA_SUCCESS,
    data,
  };
};

//MCF Integration

export const getMcfIntegrationData = (pagination, limit, data, folderName) => {
  return (dispatch) => {
    dispatch(fetchStart());
    // if (folderName === "Local") {
    //   toast.info(
    //     "Downloading has been started in background. File will be downloaded automatically once ready."
    //   );
    //   getMcfIntegrationDataApi(pagination, limit, data, folderName)
    //     .then((response) => {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       let date = Date.now();
    //       link.setAttribute("download", "McfData" + ".zip");
    //       document.body.appendChild(link);
    //       link.click();
    //       dispatch(fetchEnd());
    //     })
    // }
    getMcfIntegrationDataApi(pagination, limit, data, folderName)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response === 'No Record Found') {
          toast.error(response.data.response);
        } else {
          if (folderName === 'Local') {
            if (response.headers['content-type'] === 'application/zip') {
              toast.info(
                'Downloading has been started in background. File will be downloaded automatically once ready.',
              );
              getMcfIntegrationDataApi(
                pagination,
                limit,
                data,
                folderName,
              ).then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                let date = Date.now();
                link.setAttribute('download', 'McfData' + '.zip');
                document.body.appendChild(link);
                link.click();
                dispatch(fetchEnd());
              });
            } else {
              toast.error('No Record Found');
            }
          } else {
            toast.success(response.data.response);
          }
        }
        // if (response.data.response.length > 0) {
        //   dispatch(getMcfIntegrationDataSuccess(response.data.response));
        // } else toast.error("No records found");
      })
      .catch((error) => {
        toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};
const getMcfIntegrationDataApi = (pagination, limit, data, folderName) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (folderName === 'Local') {
    var result = axios.post(
      'payment_postings?page=' +
      pagination +
      '&limit=' +
      limit +
      '&storage_location=' +
      folderName,
      data,
      { headers: headers, responseType: 'blob' },
    );
  } else {
    var result = axios.post(
      'payment_postings?page=' +
      pagination +
      '&limit=' +
      limit +
      '&storage_location=' +
      folderName,
      data,
      { headers: headers },
    );
  }

  return result;
};

export const getMcfIntegrationDataSuccess = (data) => {
  return {
    type: MCF_INTEGRATION_SUCCESS,
    data,
  };
};

//Compare Rebate Calculation

export const getCompareRebateCalculationData = (data, report, view) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCompareRebateCalculationDataApi(data, report, view)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(
            getCompareRebateCalculationDataSuccess(response.data.response),
          );
          history.push('/compare-rebate-calculation-result');
        } else toast.error('No records found');
      })
      .catch((error) => {
        toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};
const getCompareRebateCalculationDataApi = (data, report, view) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  if (report == 'summary_level') {
    result = axios.post('compare-rebate-cal-summary?view=' + view, data, {
      headers: headers,
    });
  } else {
    result = axios.post('compare-rebate-calculations', data, {
      headers: headers,
    });
  }

  return result;
};

export const getCompareRebateCalculationDataSuccess = (data) => {
  return {
    type: GET_COMPARE_REBATE_CALCULATION_DATA_SUCCESS,
    data,
  };
};

//financial posting approval starts here
export const getFinacialPostingsApprovalData = (
  pagination,
  limit,
  keyword,
  page,
  report_version,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinacialPostingsApprovalDataApi(
      pagination,
      limit,
      keyword,
      page,
      report_version,
    ).then((response) => {
      dispatch(fetchEnd());
      console.log('23', response.data);
      if (response.data.response.total_record > 0) {
        if (page === 'Operational Report') {
          dispatch(getFinacialPostingsApprovalDataSuccess(response.data));
        } else {
          dispatch(
            getFinacialPostingsApprovalDataSuccess(response.data.response),
          );
        }

        history.push('/financial-approvals-reports/results');
        // dispatch(
        //   getFinacialPostingsApprovalDataComplete(
        //     response.data.response.total_record
        //   )
        // );
      } else toast.error('No records found');
    });
  };
};
const getFinacialPostingsApprovalDataApi = (
  pagination,
  limit,
  keyword,
  page,
  report_version,
) => {
  var data = JSON.parse(sessionStorage.getItem('FinancialReportsSummaryData'));
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword) {
    result = axios.get(
      'financial_postings/grouped?page=' +
      pagination +
      '&limit=' +
      limit +
      '&keyword=' +
      keyword,
      { params: data },
      { headers: headers },
    );
  } else if (report_version === 'display_exception') {
    result = axios.post(
      'reconciliation_report?page=' + pagination + '&limit=' + limit,
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'financial_postings/grouped?page=' +
      pagination +
      '&limit=' +
      limit +
      '&report_version=' +
      report_version,
      data,
      { headers: headers },
    );
  }
  return result;
};

export const getFinacialPostingsApprovalDataSuccess = (data) => {
  return {
    type: GET_FINANCIAL_APPROVAL_SUCCESS,
    data,
  };
};
export const getCustomerPaymentData = (pagination, limit, keyword) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCustomerPaymentDataApi(pagination, limit, keyword).then((response) => {
      dispatch(fetchEnd());
      if (response.data.response.total_record > 0) {
        dispatch(getCustomerPaymentDataSuccess(response.data.response));
        history.push('/customer-payment-summary-reports/results');
        // dispatch(
        //   getFinacialPostingsApprovalDataComplete(
        //     response.data.response.total_record
        //   )
        // );
      } else toast.error('No records found');
    });
  };
};
const getCustomerPaymentDataApi = (pagination, limit, keyword) => {
  var data = JSON.parse(sessionStorage.getItem('CustomerPaymentSummaryData'));
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword) {
    result = axios.get(
      'paymentExternalPostings?page=' +
      pagination +
      '&limit=' +
      limit +
      '&keyword=' +
      keyword,
      { params: data },
      { headers: headers },
    );
  } else {
    result = axios.post(
      'paymentExternalPostings?page=' + pagination + '&limit=' + limit,
      data,
      { headers: headers },
    );
  }
  return result;
};

export const getCustomerPaymentDataSuccess = (data) => {
  return {
    type: GET_CUSTOMER_PAYMENT_SUCCESS,
    data,
  };
};
// Integration Reconcilation Data
export const getIntegrationReconcilationData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getIntegrationReconcilationDataApi(data).then((response) => {
      dispatch(fetchEnd());
      if (response.data.response !== 'No Record Found') {
        dispatch(
          getIntegrationReconcilationDataSuccess(response.data.response),
        );
        history.push('/integration-reconcilation-result');
      } else toast.error('No records found');
    });
  };
};
const getIntegrationReconcilationDataApi = (data) => {
  // var data = JSON.parse(sessionStorage.getItem("CustomerPaymentSummaryData"));
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  // result = axios.post(
  //   "source_record_information?page=" +
  //   pagination +
  //   "&limit=" +
  //   limit, data,
  //   // { params: data },
  //   { headers: headers }
  // );
  result = axios.post(
    'source_record_information',
    data,
    // { params: data },
    { headers: headers },
  );
  return result;
};

export const getIntegrationReconcilationDataSuccess = (data) => {
  return {
    type: GET_INTEGRATION_RECONCILATION_SUCCESS,
    data,
  };
};

// new
export const finacialPostingsApprovalDataCompleteSuccess = (data) => {
  return {
    type: FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS,
    data,
  };
};

export const getFinacialPostingsApprovalDataComplete = (totalRecords) => {
  return (dispatch) => {
    getFinacialPostingsApprovalCompleteApi(totalRecords)
      .then((response) => {
        if (response.data.response.total_record > 0) {
          dispatch(
            finacialPostingsApprovalDataCompleteSuccess(response.data.response),
          );
        }
      })
      .catch((error) => { });
  };
};

const getFinacialPostingsApprovalCompleteApi = (totalRecords) => {
  const data = JSON.parse(localStorage.getItem('financialReportsSummaryData'));
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    'financial_postings/grouped?limit=' + totalRecords,
    { params: data },
    { headers: headers },
  );
  return result;
};

//batch approve
//batch approval
export const batchApproveFinancial = (data, status) => {
  return (dispatch) => {
    batchApproveFinancialApi(data, status)
      .then((response) => {
        dispatch(getFinacialPostingsApprovalData());
      })
      .catch((error) => {
        //toast.error('Something went wrong.');
      });
  };
};

const batchApproveFinancialApi = (data, status) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var formData = {
    financial_posting_ids: data,
    approval_status: status,
  };
  var result;
  result = axios.post(
    'approval-request/bulk-approval/financial-posting',
    formData,
    { headers: headers },
  );
  return result;
};

//financial posting approval end here
//get form fields all data
export const getDefaultValuesAllData = (
  fieldId,
  sourceDataType,
  applicationType,
  on,
  formId,
  page,
) => {
  return (dispatch) => {
    getDefaultValuesAllDataAPI(
      fieldId,
      sourceDataType,
      applicationType,
      on,
      formId,
    ).then((response) => {
      if (page === 'ER') {
        dispatch(getDefaultValuesAllDataAPIERSuccess(response.data.response));
      } else {
        dispatch(getDefaultValuesAllDataAPISuccess(response.data.response));
      }
    });
  };
};

const getDefaultValuesAllDataAPI = (
  fieldId,
  sourceDataType,
  applicationType,
  on,
  formId,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (on === 'on') {
    if (formId) {
      var result = axios.get(
        'form_fields?field_id=' +
        fieldId +
        '&data_source_type=' +
        sourceDataType +
        '&app_source_type=' +
        applicationType +
        '&form_id=' +
        formId,
        { headers: headers },
      );
    } else {
      var result = axios.get(
        'form_fields?field_id=' +
        fieldId +
        '&data_source_type=' +
        sourceDataType,
        { headers: headers },
      );
    }
  } else {
    var result = axios.get('form_fields?form_id=IMA_CONFIG_CONTRACT&limit=0', {
      headers: headers,
    });
  }
  return result;
};

export const getDefaultValuesAllDataAPISuccess = (data) => {
  return {
    type: FORM_FIELDS_ALL_DATA,
    data,
  };
};

export const getDefaultValuesAllDataAPIERSuccess = (data) => {
  return {
    type: FORM_FIELDS_ALL_DATA_ER,
    data,
  };
};

// Attribute Mapping API

export const getTargetSourceAttributeData = (data, page) => {
  return (dispatch) => {
    getTargetSourceAttributeDataAPI(data, page).then((response) => {
      if (page === 'SA') {
        dispatch(
          getTargetSourceAttributeDataAPISASuccess(response.data.response),
        );
      } else {
        dispatch(
          getTargetSourceAttributeDataAPISuccess(response.data.response),
        );
      }
    });
  };
};

const getTargetSourceAttributeDataAPI = (data, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };

  let result = axios.post('table-fields', data, { headers: headers });

  return result;
};
export const getTargetSourceAttributeDataAPISASuccess = (data) => {
  return {
    type: TARGET_SOURCE_ATTRIBUTE_ALL_DATA_SA,
    data,
  };
};
export const getTargetSourceAttributeDataAPISuccess = (data) => {
  return {
    type: TARGET_SOURCE_ATTRIBUTE_ALL_DATA,
    data,
  };
};

//new label
export const getDefaultValuesAllDataNew = (
  command,
  applicationType,
  sourceDataType,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDefaultValuesAllDataNewAPI(command, applicationType, sourceDataType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getDefaultValuesAllDataNewAPISuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getDefaultValuesAllDataNewAPI = (
  command,
  applicationType,
  sourceDataType,
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'field-label-designer?app_type=' +
    applicationType +
    '&data_source_type=' +
    sourceDataType,
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesAllDataNewAPISuccess = (data) => {
  return {
    type: FORM_FIELDS_ALL_DATA_NEW,
    data,
  };
};

//load key values based on application type
export const loadValuesBasedOnAppTypeAndField = (applicationType, fieldId) => {
  return (dispatch) => {
    loadValuesBasedOnAppTypeAndFieldAPI(applicationType, fieldId).then(
      (response) => {
        dispatch(
          loadValuesBasedOnAppTypeAndFieldSuccess(
            response.data.response.records[0],
          ),
        );
      },
    );
  };
};

const loadValuesBasedOnAppTypeAndFieldAPI = (applicationType, fieldId) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (applicationType == 'Pricing')
    var result = axios.get(
      'form_fields?field_id=' +
      fieldId +
      '&app_source_type=' +
      applicationType +
      '&form_id=' +
      'IMA_CONFIG_CONTRACT',
      { headers: headers },
    );
  else
    var result = axios.get(
      'form_fields?field_id=' + fieldId + '&app_source_type=' + applicationType,
      { headers: headers },
    );
  return result;
};

export const loadValuesBasedOnAppTypeAndFieldSuccess = (data) => {
  return {
    type: LOAD_FIELDVALUES_ON_APP_TYPE,
    data,
  };
};

//get single user
export const onLoadingSingleUser = (id) => {
  return (dispatch) => {
    onLoadingSingleUserAPI(id)
      .then((response) => {
        dispatch(onLoadingSingleUserAPISUCCESS(response.data.response));
      })
      .catch((error) => { });
  };
};

const onLoadingSingleUserAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('users/' + id, { headers: headers });
  return result;
};

export const onLoadingSingleUserAPISUCCESS = (data) => {
  return {
    type: USER_ROLE_SINGLE,
    data,
  };
};

//resend user email
export const resendUserEmail = (id) => {
  return (dispatch) => {
    resendUserEmailAPI(id)
      .then((response) => {
        toast.success('Mail sent successfully.');
      })
      .catch((error) => { });
  };
};

const resendUserEmailAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post('/users/' + id + '/resend-verification-mail', {
    headers: headers,
  });
  return result;
};

//delete user email
export const deleteUser = (id) => {
  return (dispatch) => {
    deleteUserAPI(id)
      .then((response) => {
        // window.location.reload();
        toast.success('User deleted successfully.');
      })
      .catch((error) => { });
  };
};

const deleteUserAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.delete('/users/' + id, { headers: headers });
  return result;
};

//get initial data if source data type not provided initially
export const getInitialDataFromSourceDataType = (
  applicationType,
  fieldId,
  page,
) => {
  return (dispatch) => {
    getInitialDataFromSourceDataTypeAPI(applicationType, fieldId).then(
      (response) => {
        if (
          response.data &&
          response.data.response &&
          response.data.response.records[0] &&
          response.data.response.records[0].drop_down_value_keys[0]
        ) {
          if (page === 'contract') {
            dispatch(
              getDefaultValuesAllDataNew(
                'field-label-designer',
                sessionStorage.getItem('application'),
                response.data.response.records[0].drop_down_value_keys[0],
              ),
            );
          }
        }
      },
    );
  };
};

const getInitialDataFromSourceDataTypeAPI = (applicationType, fieldId) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?field_id=' + fieldId + '&app_source_type=' + applicationType,
    { headers: headers },
  );
  return result;
};

//get organization email link
export const getOrganizationEmailLink = (id) => {
  return (dispatch) => {
    getOrganizationEmailLinkAPI(id)
      .then((response) => {
        if (response.data && response.data.response)
          dispatch(fetchEndOrganization(response.data.response));
      })
      .catch((error) => { });
  };
};

const getOrganizationEmailLinkAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('organizations/' + id + '/generate-reset-link', {
    headers: headers,
  });
  return result;
};

//get user email
export const getUserEmailLink = (id) => {
  return (dispatch) => {
    getUserEmailLinkAPI(id)
      .then((response) => {
        if (response.data && response.data.response)
          dispatch(getUserRoleEmailL(response.data.response));
      })
      .catch((error) => { });
  };
};

const getUserEmailLinkAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('/users/' + id + '/generate-reset-link', {
    headers: headers,
  });
  return result;
};

export const getUserRoleEmailL = (data) => {
  return {
    type: GET_USER_EMAIL_LINK,
    data,
  };
};

//batch approval workflow
export const batchApproveWorkflow = (data, status, type, contractFilters) => {
  return (dispatch) => {
    dispatch(fetchStart());
    batchApproveWorflowAPI(data, status)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        if (
          status === true &&
          response.data.response === 'Successfully updated' &&
          type === 'Accrual'
        ) {
          dispatch(
            getExternalAccuralPostingData(
              { filter_fields: contractFilters },
              '',
              'noResultPage',
            ),
          );
        } else if (
          status === true &&
          response.data.response === 'Successfully updated' &&
          type === 'Payment'
        ) {
          dispatch(
            getExternalPaymentPostingData(
              { filter_fields: contractFilters },
              '',
              'noResultPage',
            ),
          );
        }
        if (type === 'Payment') {
          // history.push("/payment-approvals");
          dispatch(
            getWorkflowRequests(type, {
              filter_fields: JSON.parse(
                sessionStorage.getItem('PaymentandAccuralApprovalfilter'),
              ),
            }),
          );
        } else if (type === 'Promotion') {
          history.push('/costing-approvals');
        } else {
          // history.push("/accrual-approvals");
          dispatch(
            getWorkflowRequests(type, {
              filter_fields: JSON.parse(
                sessionStorage.getItem('PaymentandAccuralApprovalfilter'),
              ),
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        toast.error(error.response.data.complete_error_message);
      });
  };
};

const batchApproveWorflowAPI = (data, status) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'workflow-status?approve=' + status + '&id=' + data.toString(),
    { headers: headers },
  );
  return result;
};

//get workflow requests
export const getWorkflowRequests = (fn, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getWorkflowRequestsAPI(fn, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (fn === 'Accrual') {
          dispatch(getWorkflowRequestsAccrualSuccess(response.data.response));
          history.push('/accrual-approvals/accrual-reports');
        }
        if (fn === 'Payment') {
          dispatch(getWorkflowRequestsPaymentSuccess(response.data.response));
          history.push('/payment-approvals/payment-reports');
        }
        if (fn === 'promotion') {
          dispatch(getWorkflowRequestsPromotionSuccess(response.data.response));
          history.push('/costing-approvals/costing-reports');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getWorkflowRequestsAPI = (fn, data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (fn === 'Accrual' || fn === 'Payment') {
    var result = axios.post('workflow-requests?functionality=' + fn, data, {
      headers: headers,
    });
  } else {
    var result = axios.post('workflow-requests', data, { headers: headers });
  }
  return result;
};

export const getWorkflowRequestsAccrualSuccess = (data) => {
  return {
    type: WORKFLOW_REQUEST_ACCRUAL,
    data,
  };
};

export const getWorkflowRequestsPaymentSuccess = (data) => {
  return {
    type: WORKFLOW_REQUEST_PAYMENT,
    data,
  };
};
export const getWorkflowRequestsPromotionSuccess = (data) => {
  return {
    type: WORKFLOW_REQUEST_PROMOTION,
    data,
  };
};
export const formFieldRefresh = (id) => {
  return (dispatch) => {
    formFieldRefreshApi(id)
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error(error.data.message);
      });
  };
};

const formFieldRefreshApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.put('form_fields/refresh/' + id, { headers: headers });
  return result;
};

//get user email
export const runContractTrends = (data, type, pge) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runContractTrendsAPI(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response.data &&
          response.data.response &&
          response.data.response.length === 0
        ) {
          toast.warn('No records found.');
        } else {
          if (type == 'contractV1')
            dispatch(runContractTrendsAPISuccess(response.data.response));
          else
            dispatch(
              runContractTrendsNextVersionAPISuccess(response.data.response),
            );

          if (pge === 'sf') {
            history.push({
              pathname: '/contract-trends/contract-trends-result-sf',
              data: { type: type },
            });
          }
          // else if (pge == "integration")
          //   history.push({
          //     pathname: "/contract-trends-maintenance",
          //     data: { type: type },
          //   });
          else
            history.push({
              pathname: '/contract-trends/contract-trends-result',
              data: { type: type },
            });
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};

const runContractTrendsAPI = (data, type) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type == 'contractV1')
    var result = axios.get('/contract-accuracy', {
      params: data,
      headers: headers,
    });
  else if (type == 'contractV3')
    var result = axios.get('/contract-accuracy/v3', {
      params: data,
      headers: headers,
    });
  else
    var result = axios.get('/contract-accuracy/v2', {
      params: data,
      headers: headers,
    });

  return result;
};

export const runContractTrendsAPISuccess = (data) => {
  return {
    type: CONTRACT_TRENDS,
    data,
  };
};
export const runContractTrendsNextVersionAPISuccess = (data) => {
  return {
    type: CONTRACT_TRENDS_V2,
    data,
  };
};

export const runContractTrendsMultiple = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runContractTrendsMultipleAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response.data &&
          response.data.response &&
          response.data.response.length === 0
        ) {
          toast.warn('No records found.');
        } else {
          dispatch(runContractTrendsMultipleAPISuccess(response.data.response));
          history.push('/contract-trends-multiple-results');
          // history.push({
          //   pathname: "/contract-trends-multiple-results",
          //   // data: { type: type },
          // });
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
        dispatch(fetchEnd());
      });
  };
};

const runContractTrendsMultipleAPI = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('/calc-sim-analysis', data, { headers: headers });
  return result;
};

export const runContractTrendsMultipleAPISuccess = (data) => {
  return {
    type: CONTRACT_TRENDS_MULTIPLE,
    data,
  };
};

//calculation simulation numbers
export const getCalculationSimulationNumbers = (e, sd, ed, postingType) => {
  return (dispatch) => {
    getCalculationSimulationNumbersAPI(e, sd, ed, postingType)
      .then((response) => {
        dispatch(
          getCalculationSimulationNumbersSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error('Something went wrong')
      });
  };
};

const getCalculationSimulationNumbersAPI = (e, sd, ed, postingType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (postingType != 'calculation_simulation')
    var result = axios.get(
      'calc-sim-number?contract_number=' +
      e +
      '&start_date=' +
      sd +
      '&end_date=' +
      ed +
      '&posting_type=' +
      postingType,
      { headers: headers },
    );
  else
    var result = axios.get(
      'calc-sim-number?contract_number=' +
      e +
      '&start_date=' +
      sd +
      '&end_date=' +
      ed,
      { headers: headers },
    );
  return result;
};

export const getCalculationSimulationNumbersSuccess = (data) => {
  return {
    type: CALCULATION_SIMULATION_NUMBERS,
    data,
  };
};

//contract mass upload

export const uploadMassFile = (appType, file, version) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMassFileApi(appType, file, version)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(uploadMassFileResponse(response.data));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadMassFileApi = (appType, file, version) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (version === 'v2') {
    result = axios.post(
      'contract-upload/v2?application_type=' + appType,
      file,
      { headers: headers },
    );
  } else {
    result = axios.post('contract-upload?application_type=' + appType, file, {
      headers: headers,
    });
  }
  return result;
};
export const uploadMassFileResponse = (data) => {
  return {
    type: UPLOAD_MASS_FILE,
    data,
  };
};

//update org defaults
export const updateOrgDefaults = (data) => {
  return (dispatch) => {
    updateOrgDefaultsAPI(data).then((response) => {
      toast('Updated');
    });
  };
};

const updateOrgDefaultsAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.post('organizations-default-details', data, {
    headers: headers,
  });
  return result;
};

//get org defaults
export const getOrgDefaults = (reset) => {
  return (dispatch) => {
    if (reset === 'reset') {
      dispatch(getOrgDefaultsSuccess({}));
    } else
      getOrgDefaultsAPI(reset).then((response) => {
        dispatch(getOrgDefaultsSuccess(response.data.response));
      });
  };
};

const getOrgDefaultsAPI = (reset) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (reset === 'reset') {
    result = {};
  } else {
    result = axios.get('organizations-default-details', { headers: headers });
  }
  return result;
};

export const getOrgDefaultsSuccess = (data) => {
  return {
    type: ORG_DEFAULTS,
    data,
  };
};

//get claim error count
export const claimErrorCount = (sd, ed, applicationType) => {
  return (dispatch) => {
    claimErrorCountAPI(sd, ed, applicationType).then((response) => {
      if (
        response.data.response.rejection_reason &&
        response.data.response.rejection_reason.length > 0
      ) {
        history.push('/rejected-dashboard');
        dispatch(claimErrorSuccess(response.data.response));
      } else {
        toast.warn('No records found.');
      }
    });
  };
};

const claimErrorCountAPI = (sd, ed, applicationType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'claim-error-count?application_type=' +
    applicationType +
    '&start_date=' +
    sd +
    '&end_date=' +
    ed,
    { headers: headers },
  );
  return result;
};

export const claimErrorSuccess = (data) => {
  return {
    type: CLAIM_ERROR_COUNT,
    data,
  };
};

export const submitExcel = (data, type) => {
  return (dispatch) => {
    submitExcelAPI(data, type)
      .then((response) => {
        if (type == 'List Price') {
          toast.success(response.data.response);
          history.push('/list-price');
        } else {
          toast.success('Successfully created.');
          history.push('/competitor-data');
        }
      })
      .error((error) => {
        //toast.error('Error')
      });
  };
};

const submitExcelAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type == 'List Price') {
    var result = axios.post('market-price', data, { headers: headers });
  } else {
    var result = axios.post('market-share', data, { headers: headers });
  }
  return result;
};

//app rights
export const appRights = (name) => {
  return (dispatch) => {
    appRightsAPI(name)
      .then((response) => {
        dispatch(appRightsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

export const appRightsSuccess = (data) => {
  return {
    type: APP_RIGHTS,
    data,
  };
};

const appRightsAPI = (name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('app-rights?application_type=' + name, {
    headers: headers,
  });
  return result;
};

export const getTableName = () => {
  return (dispatch) => {
    getTableNameAPI().then((response) => {
      dispatch(getTableNameSuccess(response.data.response));
    });
  };
};
const getTableNameAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  //var result = axios.get("display-field-tables", { headers: headers });
  var result = axios.get('field-label-tables', { headers: headers });
  return result;
};
export const getTableNameSuccess = (data) => {
  return {
    type: TABLES_NAMES_SUCCESS,
    data,
  };
};
export const getLblDispDesFieldValue = (data, type) => {
  // console.log(data)
  return (dispatch) => {
    dispatch(fetchStart());
    getLblDispDesFieldValuedAPI(data, type).then((response) => {
      if (type === 'post') {
        dispatch(fetchEnd());
        dispatch(getLblDispDesFieldValueSuccess(response.data.response));
      } else if (type === 'reset') {
        dispatch(fetchEnd());
        toast.success('Successfully Reset.');
        dispatch(getLblDispDesFieldValueSuccess(response.data.response));
      } else {
        dispatch(fetchEnd());
        toast.success('Successfully updated.');
        dispatch(getLblDispDesFieldValueSuccess(response.data.response));
      }
    });
  };
};
const getLblDispDesFieldValuedAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'post') {
    result = axios.post('field-label-display', data, { headers: headers });
    //var result = axios.post("display-field-labels", data, { headers: headers });
  } else if (type === 'reset') {
    // var result = axios.put("reset-display-field-labels", data, {
    //   headers: headers,
    // });
    result = axios.put('field-label-display/reset', data, {
      headers: headers,
    });
  } else {
    console.log('data');
    result = axios.put('field-label-display', data, { headers: headers });
    //result = axios.put("display-field-labels", data, { headers: headers });
    console.log(result);
  }
  //var result = axios.put("display-field-labels", data, { headers: headers });
  return result;
};
export const getLblDispDesFieldValueSuccess = (data) => {
  return {
    type: DEAFULT_VALUES_LBL_DISP,
    data,
  };
};

//Delete Master Data
export const getDeleteMasterData = (type, data) => {
  return (dispatch) => {
    getDeleteMasterDataAPI(type, data).then((response) => {
      toast.success(response.data.response);
    });
  };
};
const getDeleteMasterDataAPI = (type, data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ',' ||
      data[propName] === 'Invalid date'
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.delete(
    '/master-data?AppFunc=' + type,
    { params: data },
    { headers: headers },
  );
  return result;
};
export const getDeleteMasterDataSuccess = (data) => {
  return {
    type: DELETE_MASTER_DATA_SUCCESS,
    data,
  };
};

export const getDeleteMasterDataField = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDeleteMasterDataFieldAPI(type).then((response) => {
      dispatch(fetchEnd());
      dispatch(getDeleteMasterDataFieldSuccess(response.data.response));
    });
  };
};
const getDeleteMasterDataFieldAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('/upload-format-fields?app-functionality=' + type, {
    headers: headers,
  });
  return result;
};
export const getDeleteMasterDataFieldSuccess = (data) => {
  return {
    type: DELETE_MASTER_DATA_FIELD_SUCCESS,
    data,
  };
};

export const getContractDisplayDesigner = (appType) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractDisplayDesignerdAPI(appType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getContractDisplayDesignerSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getContractDisplayDesignerdAPI = (appType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('form-fields-contract?application_type=' + appType, {
    headers: headers,
  });
  return result;
};
export const getContractDisplayDesignerSuccess = (data) => {
  return {
    type: CONTRACT_DISPLAY,
    data,
  };
};

export const runContractDisplayDesigner = (data, appType) => {
  return (dispatch) => {
    runContractDisplayDesignerdAPI(data, appType).then((response) => {
      toast.success(response.data.response);
      dispatch(getContractDisplayDesigner(appType));
    });
  };
};
const runContractDisplayDesignerdAPI = (data, appType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.put(
    'form-fields-contract?application_type=' + appType,
    data,
    { headers: headers },
  );
  return result;
};

export const getCollaboratedData = (key) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCollaboratedDatadAPI(key)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCollaboratedDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getCollaboratedDatadAPI = (key) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('collaboration-dropdown?key=' + key, {
    headers: headers,
  });
  return result;
};
export const getCollaboratedDataSuccess = (data) => {
  return {
    type: COLLABORATED_DATA,
    data,
  };
};
export const getConfigurationDesigner = () => {
  return (dispatch) => {
    getConfigurationDesignerAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getConfigurationDesignerSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getConfigurationDesignerAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('document-assignment-config', { headers: headers });
  return result;
};

export const getConfigurationDesignerSuccess = (data) => {
  return {
    type: CONFIGURATION_DESIGNER_SUCCESS,
    data,
  };
};

export const getReportTypeFilter = (appType, data) => {
  return (dispatch) => {
    getReportTypeFilterAPI(appType, data)
      .then((response) => {
        dispatch(getReportTypeFilterSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getReportTypeFilterAPI = (appType, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'reports?application_type=' + appType + '&functionality=' + data,
    { headers: headers },
  );
  return result;
};

export const getReportTypeFilterSuccess = (data) => {
  return {
    type: REPORT_TYPE_FILTER_SUCCESS,
    data,
  };
};

export const addReportTypeFilter = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addReportTypeFilterApi(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('Successfully added.');
        history.push('/reporting-by-function');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        history.push('/reporting-by-function');
      });
  };
};

const addReportTypeFilterApi = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('reports-by-functionality/' + id, data, {
      headers: headers,
    });
  } else {
    result = axios.post('reports-by-functionality', data, { headers: headers });
  }

  return result;
};

export const getReportTypeData = () => {
  return (dispatch) => {
    getReportTypeDataAPI()
      .then((response) => {
        dispatch(getReportTypeDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getReportTypeDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('reports-by-functionality', { headers: headers });

  return result;
};

export const getReportTypeDataSuccess = (data) => {
  return {
    type: REPORT_TYPE_DATA_ALL,
    data,
  };
};
export const getReportOnRollData = (type) => {
  return (dispatch) => {
    getReportOnRollDataAPI(type)
      .then((response) => {
        dispatch(getReportOnRollDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getReportOnRollDataAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'reports-by-functionality/grouped?reporting_category=' + type,
    { headers: headers },
  );

  return result;
};

export const getReportOnRollDataSuccess = (data) => {
  return {
    type: REPORT_ON_ROLL_ALL,
    data,
  };
};

export const getSingleReportTypeData = (id) => {
  return (dispatch) => {
    getSingleReportTypeDataAPI(id)
      .then((response) => {
        dispatch(getSingleReportTypeDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};

const getSingleReportTypeDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('reports-by-functionality/' + id, { headers: headers });
  return result;
};

export const getSingleReportTypeDataSuccess = (data) => {
  return {
    type: REPORT_TYPE_DATA_SINGLE,
    data,
  };
};

export const deleteReportTypeData = (id) => {
  return (dispatch) => {
    deleteReportTypeDataApi(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getReportTypeData());
      })
      .catch((error) => { });
  };
};

const deleteReportTypeDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('reports-by-functionality/' + id, { headers: headers });
  return result;
};

export const createConfigurationDesigner = (
  data,
  type,
  id,
  selectedRowName,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createConfigurationDesignerAPI(data, type, id, selectedRowName)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/config-designer');
        dispatch(getConfigurationDesigner());
        if (type == 'edit') {
          toast.success('Successfully Updated.');
        } else if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else {
          toast.success('Successfully Created.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createConfigurationDesignerAPI = (data, type, id, selectedRowName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (selectedRowName === 'workflow_assignment') {
    if (type === 'create') {
      result = axios.post('document-assignment-config', data, {
        headers: headers,
      });
    } else {
      result = axios.put('document-assignment-config/' + id, data, {
        headers: headers,
      });
    }
  } else if (selectedRowName === 'suppress_online_run') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?suppress_online_run=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?suppress_online_run=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'calculation_performance_improvement') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?calculation_performance_improvement=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' +
        id +
        '?calculation_performance_improvement=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'maximum_calculation_lines') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?maximum_calculation_lines=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?maximum_calculation_lines=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'maximum_on_demand_lines') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?maximum_on_demand_lines=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?maximum_on_demand_lines=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'posting_parallel_run') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?posting_parallel_run=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?posting_parallel_run=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'calculation_parallel_run') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?calculation_parallel_run=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?calculation_parallel_run=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'formula_category') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?formula_category=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?formula_category=true',
        data,
        { headers: headers },
      );
    }
  } else if (selectedRowName === 'save_master_data_history') {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?save_master_data_history=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?save_master_data_history=true',
        data,
        { headers: headers },
      );
    }
  } else {
    if (type === 'create') {
      result = axios.post(
        'document-assignment-config?reporting_category=true',
        data,
        { headers: headers },
      );
    } else {
      result = axios.put(
        'document-assignment-config/' + id + '?reporting_category=true',
        data,
        { headers: headers },
      );
    }
  }

  return result;
};

export const getContractAttributes = (filedName) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractAttributesAPI(filedName)
      .then((response) => {
        dispatch(getContractAttributesSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getContractAttributesAPI = (filedName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.get(
    'form_fields?form_id=IMA_CONFIG_CONTRACT&field_id=' +
    filedName +
    '&limit=1',
    { headers: headers },
  );
};
export const getContractAttributesSuccess = (data) => ({
  type: GET_CONTRACT_ATTRIBUTES_SUCCESS,
  data,
});
export const updateContractAttributes = (data, fieldName) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateContractAttributesAPI(data, fieldName)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('Contract Attribute Saved Successfully !');
        dispatch(
          getContractAttributesAllData({
            data_source: 'contract_header',
            application_type: 'Customer Rebate',
          }),
        );
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const updateContractAttributesAPI = (data, fieldName) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'modify_form_fields?form_id=IMA_CONFIG_CONTRACT&field_id=' + fieldName,
    data,
    { headers: headers },
  );
  return result;
};

export const getContractAttributesAllDataSuccess = (data) => ({
  type: CONTRACT_ALL_ATTRIBUTES,
  data,
});

const getContractAttributesAllDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.post('field-label-display', data, { headers: headers });
};
export const getContractAttributesAllData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractAttributesAllDataAPI(data)
      .then((response) => {
        dispatch(getContractAttributesAllDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

export const deleteFinancialPosting = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteFinancialPostingApi(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response.message);
        if (type === 'workflow') {
          let data = JSON.parse(
            sessionStorage.getItem('WorkflowDynamicFieldValues'),
          );
          dispatch(getWorkflowMaintenanceData(data));
        } else {
          let data = JSON.parse(
            sessionStorage.getItem('PostingDynamicFieldValues'),
          );
          dispatch(getPostings(data));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(response.data.error);
      });
  };
};
const deleteFinancialPostingApi = (data, type) => {
  console.log(data);
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type === 'workflow') {
    var result = axios.delete('workflow-delete', {
      headers: headers,
      data: data,
    });
  } else {
    var result = axios.delete('postings-delete', {
      headers: headers,
      data: data,
    });
  }
  return result;
};

// get Daily Simulations Report
export const getDailySimulationsReport = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getDailySimulationsReportAPI(data)
      .then((response) => {
        // console.log(response.data.response)
        dispatch(fetchEnd());
        dispatch(getDailySimulationsReportSuccess(response.data.response));
        history.push('/daily-simulations-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getDailySimulationsReportAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.get(
    'calculation_aggregate_simulations',
    { params: data },
    { headers: headers },
  );
};
export const getDailySimulationsReportSuccess = (data) => ({
  type: GET_DAILY_SIMULATIONS_REPORT_SUCCESS,
  data,
});

export const getPostings = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPostingsApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPostingsSuccess(response.data.response));
        history.push('/postings-result');
        // toast.success("Postings retrieved successfully");
        // Handle the response data as needed
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error("Error retrieving postings");
        // Handle the error as needed
      });
  };
};

const getPostingsApi = (data) => {
  // console.log(data);
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    // 'Content-Type': 'application/json',
  };
  return axios.post('get-postings', data, { headers: headers });
};
export const getPostingsSuccess = (data) => {
  return {
    type: POSTINGS_DATA,
    data,
  };
};
export const getWorkflowMaintenanceData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getWorkflowMaintenanceDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getWorkflowMaintenanceDataSuccess(response.data.response));
        history.push('/workflow-maintenance-result');
        // toast.success("Postings retrieved successfully");
        // Handle the response data as needed
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error("Error retrieving postings");
        // Handle the error as needed
      });
  };
};

const getWorkflowMaintenanceDataApi = (data) => {
  // console.log(data);
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    // 'Content-Type': 'application/json',
  };
  return axios.post('get-workflow_requests', data, { headers: headers });
};
export const getWorkflowMaintenanceDataSuccess = (data) => {
  return {
    type: WORKFLOW_MAINTENANCE_DATA,
    data,
  };
};
export const getManEligRulesDesigner = () => {
  return (dispatch) => {
    getManEligRulesDesignerAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getManEligRulesDesignerSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getManEligRulesDesignerAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'default-configuration?key_name=contract_eligibility_confgiuration',
    { headers: headers },
  );
  return result;
};

export const getManEligRulesDesignerSuccess = (data) => {
  return {
    type: MANDATORY_ELIGIBILITY_RULES_SUCCESS,
    data,
  };
};
export const createManEligRulesDesigner = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createManEligRulesDesignerAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/mandatory-eligibility-rules');
        dispatch(getManEligRulesDesigner());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createManEligRulesDesignerAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create') {
    result = axios.post('default-configuration', data, { headers: headers });
  } else if (type === 'clear') {
    result = axios.delete('default-config/' + id, { headers: headers });
  } else {
    result = axios.put('default-configuration/' + id, data, {
      headers: headers,
    });
  }
  return result;
};

//standard compliance report

export const getStandardComplianceData = (appType) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getStandardComplianceDataApi(appType).then((response) => {
      dispatch(fetchEnd());
      if (response.data.response) {
        dispatch(getStandardComplianceDataSuccess(response.data.response));
        history.push('/standard-compliance-report/results');
      } else toast.error('No records found');
    });
  };
};
const getStandardComplianceDataApi = (appType) => {
  var data = JSON.parse(
    sessionStorage.getItem('StandardComplianceSummaryData'),
  );
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'integration-setup/compliance-report?application_type=' + appType,
    data,
    { headers: headers },
  );
  return result;
};

export const getStandardComplianceDataSuccess = (data) => {
  return {
    type: GET_STANDARD_COMPLIANCE_SUCCESS,
    data,
  };
};
export const getMessageMaintenanceListData = () => {
  return (dispatch) => {
    getMessageMaintenanceListDataAPI()
      .then((response) => {
        dispatch(getMessageMaintenanceListDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getMessageMaintenanceListDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('message?limit=0', { headers: headers });

  return result;
};

export const getMessageMaintenanceListDataSuccess = (data) => {
  return {
    type: MESSAGE_MAINTENANCE_ALL_DATA,
    data,
  };
};

export const getSingleMessageMaintenanceData = (id) => {
  return (dispatch) => {
    getSingleMessageMaintenanceDataAPI(id)
      .then((response) => {
        dispatch(
          getSingleMessageMaintenanceDataSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getSingleMessageMaintenanceDataAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('message/' + id, { headers: headers });
  return result;
};

export const getSingleMessageMaintenanceDataSuccess = (data) => {
  return {
    type: MESSAGE_MAINTENANCE_DATA_SINGLE,
    data,
  };
};

export const deleteMessageMaintenanceData = (id) => {
  return (dispatch) => {
    deleteMessageMaintenanceDataApi(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getMessageMaintenanceListData());
      })
      .catch((error) => { });
  };
};

const deleteMessageMaintenanceDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('message/' + id, { headers: headers });
  return result;
};
export const addMessageMaintenanceData = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMessageMaintenanceDataApi(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'edit') {
          toast.success('Successfully Updated.');
        } else {
          toast.success('Successfully Added.');
        }
        history.push('/message-maintenance');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        history.push('/message-maintenance');
      });
  };
};

const addMessageMaintenanceDataApi = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('message/' + id, data, { headers: headers });
  } else {
    result = axios.post('message', data, { headers: headers });
  }

  return result;
};
export const getMessageMaintenanceID = (data) => {
  return (dispatch) => {
    getMessageMaintenanceIDAPI(data)
      .then((response) => {
        dispatch(getMessageMaintenanceIDSuccess(response.data.response));
        console.log(response);
      })
      .catch((error) => { });
  };
};

const getMessageMaintenanceIDAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('message-id', { params: data }, { headers: headers });
  return result;
};

export const getMessageMaintenanceIDSuccess = (data) => {
  return {
    type: MESSAGE_MAINTENANCE_ID_DATA,
    data,
  };
};
